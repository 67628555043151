import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

export interface ILocation {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
}

/**
 * For SSR, see: https://github.com/angular/universal/blob/main/docs/gotchas.md#window-is-not-defined
 */
@Injectable()
export class WindowRef {
  constructor(@Inject(DOCUMENT) public document: Document) {}

  get instance(): Window {
    return this.document.defaultView as Window;
  }

  get devicePixelRatio(): number {
    return this.instance.devicePixelRatio ?? 1;
  }

  get(key: string) {
    return (<any>this.instance)[key];
  }

  set(key: string, value: any) {
    (<any>this.instance)[key] = value;
  }

  get documentHead() {
    return this.document.head;
  }

  get documentBody() {
    return this.document.body;
  }

  get window() {
    return this.document.defaultView;
  }

  get location(): Readonly<ILocation> | undefined {
    return this.window?.location;
  }

  get userAgent(): string | undefined {
    return this.window?.navigator.userAgent;
  }

  print() {
    this.instance?.print();
  }

  open(url: string, target?: string) {
    this.instance?.open(url, '_blank');
  }

  openNewTab(url: string) {
    this.open(url, '_blank');
  }

  getCurrentPosition(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      if (this.instance.navigator.geolocation) {
        this.instance.navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });
  }

  getCurrentSelection() {
    return this.instance.getSelection()?.toString();
  }

  async hasGeolocationPermission(): Promise<boolean> {
    if ('permissions' in navigator) {
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
        // Return true if the permission state is 'granted', false otherwise
        return permissionStatus.state === 'granted';
      } catch (error) {
        console.error('Error checking geolocation permission:', error);
        return false; // Assume no permission in case of an error
      }
    } else {
      // Assume no permission if the Permissions API is not supported
      console.warn('Permissions API is not supported by this browser.');
      return false;
    }
  }

  getBrowserCookie(cookieName: string) {
    // In React Native environments, `document.cookie` doesn't exist.
    if (!this.document?.cookie) return;

    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(this.document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return undefined;
  }
}
